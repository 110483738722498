// import axios from "axios";

// export class Widget {
//     constructor({ position = 'bottom-right'}) {
//         this.defaultPosition = position;
//         this.baseUrl  = 'https://api.walletly.ai/';
//         this.open     = false;
//         this.getCampaign   = this.getCampaign();
//         this.getBrand      = this.getBrand();
//         this.getChannel    = this.getChannel();
//         this.createStyles();
//         this.GiftIcon         = window.location.origin + '/assets/gift.svg';
//         this.StarIcon         = window.location.origin + '/assets/star.svg';
//         this.brand            = {};
//         this.channel          = {};
//         this.redeemingWays    = [];
//         this.campaign         = {};
//         this.growthTool       = {};
//         this.earningWays      = {};
//         this.flows            = {};
//         this.passGrowthtool   = {};
//         this.brandDetails     = document.currentScript.getAttribute('brand').split('&')
//     }

    

//     getPosition(position) {
//         const [vertical, horizontal] = (this.channel?.position || position).split('-');
//         return {
//             [vertical]: '0px',
//             [horizontal]: '0px',
//         };
//     }

//     initialise(growthTool) {
//         setTimeout(() => {
//             const script = document.createElement('script');
//             script.src = 'https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js';
//             document.body.appendChild(script);

//             const container = document.createElement('div');
//             container.style.position = 'fixed';
//             container.style.zIndex = '1000';
//             container.style.left = '0px !important';
            
//             // Use channel position or default
//             this.position = this.getPosition(this.defaultPosition);
//             Object.keys(this.position).forEach(key => container.style[key] = this.position[key]);
            
//             document.body.appendChild(container);

//             const buttonContainer = document.createElement('div');
//             buttonContainer.classList.add('button-container');
//             buttonContainer.setAttribute("id", "widgetButton");

//             // Apply channel styles
//             const chatIcon = document.createElement('p');
//             chatIcon.innerHTML = this.channel?.widget?.text || growthTool.widget.heading;
//             chatIcon.style.color = this.channel?.widget?.primaryColor || '#ffffff';
//             chatIcon.style.background = this.channel?.widget?.backgroundColor || '#000000';
//             chatIcon.classList.add('icon');
//             console.log(this.channel?.widget?.size);//medium
//             console.log(this.channel?.widget?.position);//"left-top"
            

//             // Add click handler for URL
//             buttonContainer.addEventListener('click', () => {
//                 if (this.channel?.widgetButton?.url) {
//                     window.open(this.channel.widgetButton.url, '_blank');
//                 }
//             });

//             buttonContainer.appendChild(chatIcon);
//             container.appendChild(buttonContainer);
//             this.applyThemeChange();

//         }, 1000);
//     }

    // async getBrand(){
    //     let brandId = document.currentScript.getAttribute('brand').split('&')[1]
    //     await axios.get(`https://api.walletly.ai/api/v4/brand?_id=${brandId}`).then((resp) => {
    //         this.brand = resp.data.data[0];
    //     }).catch(function (error) {
    //     })
    // }

    // async getCampaign(){
    //     let brandId = document.currentScript.getAttribute('brand').split('&')[1]
    //     await axios.get(`https://api.walletly.ai/api/v4/campaign/public?brandId=${brandId}&campaignType=5`).then((resp) => {
    //         console.log('campaign: ', resp);
    //         this.campaign = resp.data.data[0];
    //     }).catch(function (error) {
    //         console.log('err', error)
    //     })

    //     await axios.get(`https://api.walletly.ai/api/v4/growthtool?brandId=${brandId}&growthtoolType=widget&campaignId=${this.campaign._id}`).then((resp) => {
    //         this.growthTool = resp.data.data[0];
    //         console.log('growthtool ', resp.data.data)

    //     }).catch(function (error) {
    //     })

    //     await axios.get(`https://api.walletly.ai/api/v4/earning/public?brandId=${brandId}&campaignId=${this.campaign._id}`).then((resp) => {
    //         let filteredEarningWays = resp.data.data.filter((earningWay)=>{
    //             return ((earningWay.active) && (earningWay.activity != "checkIn"))
    //         })
    //         this.earningWays = filteredEarningWays;

    //     }).catch(function (error) {
    //     })

    //     this.initialise(this.growthTool);
    // }

//     async getChannel() {
//         console.log(document.currentScript.getAttribute('brand').split('&')[1]);
        
//         await axios.get(`${this.baseUrl}/api/v4/growthtool?brandId=${document.currentScript.getAttribute('brand').split('&')[1]}&growthtoolType=widget`).then((resp) => {
//             this.channel = resp.data.data[0];
//             console.log('resp.data.data[0]',resp.data.data[0]);
//         }).catch(error => {
//             console.error('Error fetching channel:', error);
//         });
//     }


    // applyThemeChange(){
    //     document.getElementsByClassName('icon')[0].style.background = this.growthTool.widget.primaryColor;
    //     if(this.growthTool.widget.theme == 'light'){
    //         document.getElementById         ('brandName').style.color           = "#000000"
    //         document.getElementsByClassName('lowerBox')[0].style.background     = 'rgb(245, 245, 245)'
    //         document.getElementsByClassName('bottomBox')[0].style.background    = '#ffffff'
    //         document.getElementsByClassName('upperDescription')[0].style.color  = 'rgb(89, 97, 115)'
    //         document.getElementsByClassName('upperDescription')[1].style.color  = 'rgb(89, 97, 115)'
    //         document.getElementsByClassName('ways')[0].style.color              = '#000000'
    //         document.getElementsByClassName('ways')[1].style.color              = '#000000'
    //         document.getElementsByClassName('upperBox')[0].style.background     = '#F5F5F5'
    //     }
    //     else{
    //         if(document.getElementById         ('brandName'))document.getElementById         ('brandName').style.color           = "#ffffff"
    //         // document.getElementsByClassName('lowerBox')[0].style.background     = 'rgb(46, 48, 57)'
    //         // document.getElementsByClassName('bottomBox')[0].style.background    = 'rgb(20, 20, 20)'
    //         // document.getElementsByClassName('upperBox')[0].style.background     = 'rgb(46, 48, 57)'
    //         // document.getElementsByClassName('upperDescription')[0].style.color  = '#f5f7fc'
    //         // document.getElementsByClassName('upperDescription')[1].style.color  = '#f5f7fc'
    //         // document.getElementsByClassName('upperTitle')[0].style.color        = 'rgb(252, 252, 253)'
    //         // document.getElementsByClassName('ways')[0].style.color              = 'rgb(252, 252, 253)'
    //     }
    //     // document.getElementsByClassName('topBox')[0].style.background     = this.growthTool.widget.primaryColor
    //     // document.getElementsByClassName('joinBtn')[0].style.background    = this.growthTool.widget.primaryColor
    //     // if(document.getElementById('registerConfirmBtn')){document.getElementById('registerConfirmBtn').style.background = this.growthTool.widget.primaryColor}
    //     // if(document.getElementById('signInConfirmBtn')){document.getElementById('signInConfirmBtn').style.background = this.growthTool.widget.primaryColor}
    //     // document.getElementsByClassName('signIn')[0].style.color = this.growthTool.widget.primaryColor
    //     // document.getElementById         ('star').style.fill      = this.growthTool.widget.primaryColor

    //     // let earnWays = document.getElementById('mainEarnWays');
    //     // if(earnWays){
    //     //     earnWays.addEventListener('click', ()=>{
    //     //         let EarningWaysBox = document.getElementById('earningWaysBox')
    //     //         EarningWaysBox.style.display = "block"
    
    //     //         let subtitle                  = document.createElement('p');
    //     //         subtitle.innerHTML        = "Earning Ways: ";
    //     //         subtitle.style.color      = this.growthTool.widget.theme == 'dark' ? '#ffffff' : '#000000'
    //     //         subtitle.style.marginLeft = '24px'
    //     //         EarningWaysBox.style.overflow = "auto"
            
    //     //         EarningWaysBox.appendChild(subtitle);
    //     //         for(let earnway of this.earningWays){
    //     //             console.log('1',earnway)
    //     //             var singleEarnWay             = document.createElement('div');
                    
    //     //             let singleEarningways         = `
    //     //             <div id="singleDeal" style="display: flex; margin: 0px 24px 0px 24px !important; align-items: center; border-bottom: 1px solid ${this.growthTool.widget.theme == 'light' ? 'lightgray' : '#333333'}; padding: 20px 0px;">
    //     //                     <img src=${
    //     //                         earnway.activity === "checkIn" ? "https://api.walletly.ai/images/loyalty/checkin.png" :
    //     //                         earnway.activity === "signup" ? "https://api.walletly.ai/images/loyalty/smile.png" :
    //     //                         earnway.activity === "birthday" ? "https://api.walletly.ai/images/loyalty/birthday.png" :
    //     //                         earnway.activity === "spending" ? "https://api.walletly.ai/images/loyalty/spending.png" :
    //     //                         earnway.icon} style="height: 32px; overflow: hidden; object-fit: contain; width: 32px; border-radius: 4px; border: 1px solid ${this.growthTool.widget.theme == 'light' ? 'lightgray' : '#333333'}" />
    //     //                     <div style="width: 200px; margin-left: 16px; margin-right:16px;">
    //     //                         <div class="capitalize" style="color:${this.growthTool.widget.theme == 'light' ? '#000000' : 'rgb(252, 252, 253)'}; font-size: 14; overflow: hidden;  text-align: left; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; font-weight: 400;">
    //     //                             ${earnway.activity}
    //     //                         </div>
    //     //                         <div style="font-size: 14px; margin-top: 4px; text-align: left; font-weight: 400; color:${this.growthTool.widget.theme == 'light' ? 'rgb(89, 97, 115)' : 'rgba(140, 148, 166, 1)'}">
    //     //                             ${earnway?.description ? earnway?.description : "b"}
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //             `
    //     //             singleEarnWay.innerHTML = singleEarningways;
    //     //             EarningWaysBox.appendChild(singleEarnWay);
    //     //         }
                
    //     //         let closeIcon                 = document.createElement('div');
    //     //         closeIcon.setAttribute('id','closeIconQr');
    //     //         closeIcon.style.display = "block";
    
    //     //         let closeIconSvg  = document.createElement('div');
    //     //         closeIconSvg.innerHTML = `
    //     //             <div>
    //     //                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     //                 <path fill=${this.growthTool.widget.theme == 'light' ? '#000000' : '#ffffff'} fill-rule="evenodd" clip-rule="evenodd" d="M0.450509 0.476124C0.632436 0.294813 0.879047 0.192972 1.13617 0.192972C1.39329 0.192972 1.63991 0.294813 1.82183 0.476124L5.98755 4.63292L10.1533 0.476124C10.2421 0.381 10.3492 0.304703 10.4682 0.251786C10.5873 0.198868 10.7157 0.170413 10.846 0.168119C10.9763 0.165826 11.1057 0.18974 11.2265 0.238435C11.3473 0.28713 11.4571 0.359609 11.5492 0.451547C11.6414 0.543486 11.714 0.653 11.7628 0.773558C11.8116 0.894115 11.8356 1.02325 11.8333 1.15325C11.831 1.28325 11.8024 1.41145 11.7494 1.53022C11.6964 1.64898 11.6199 1.75587 11.5246 1.84451L7.35888 6.0013L11.5246 10.1581C11.6199 10.2467 11.6964 10.3536 11.7494 10.4724C11.8024 10.5912 11.831 10.7194 11.8333 10.8494C11.8356 10.9794 11.8116 11.1085 11.7628 11.229C11.714 11.3496 11.6414 11.4591 11.5492 11.5511C11.4571 11.643 11.3473 11.7155 11.2265 11.7642C11.1057 11.8129 10.9763 11.8368 10.846 11.8345C10.7157 11.8322 10.5873 11.8037 10.4682 11.7508C10.3492 11.6979 10.2421 11.6216 10.1533 11.5265L5.98755 7.36969L1.82183 11.5265C1.6379 11.6975 1.39462 11.7906 1.14326 11.7862C0.891889 11.7818 0.652056 11.6801 0.474285 11.5028C0.296513 11.3254 0.194683 11.086 0.190248 10.8352C0.185813 10.5844 0.279119 10.3416 0.450509 10.1581L4.61623 6.0013L0.450509 1.84451C0.268808 1.66297 0.166748 1.41689 0.166748 1.16032C0.166748 0.903744 0.268808 0.657662 0.450509 0.476124V0.476124Z" />
    //     //                 </svg>
    //     //             </div>
    //     //         `
    //     //         closeIcon.appendChild(closeIconSvg);
    //     //         console.log('close Icon ', closeIcon);
    //     //         closeIcon.addEventListener('click', (event)=>{
    //     //             EarningWaysBox.innerHTML = "";
    //     //             EarningWaysBox.style.display = "none";
    //     //         })
    
    //     //         EarningWaysBox.appendChild(closeIcon);
    
    //     //         console.log('this ', this.earningWays)
    //     //     })
    //     // }

    // }   


    // createStyles() {
    //     const styleTag = document.createElement('style');
    //     styleTag.innerHTML = `
    //         .icon {
    //             cursor          : pointer;
    //             width           : fit-content;
    //             height          : fit-content;
    //             position        : absolute;
    //             bottom          : 120px;
    //             border-radius   : 3px;
    //             left            : -54px;
    //             padding         : 8px 16px;
    //             transition      : transform .3s ease;
    //             font-family     : "Inter", sans-serif;
    //             font-size       : 14px;
    //             color           : white;
    //             line-height     : 16px;
    //             letter-spacing  : 0.1px;
    //             margin          : 0px;
    //             letter-spacing  : 1.5px;
    //             font-weight     : 500;
    //             white-space     : nowrap;
    //             position        : fixed;
    //             z-index         : 10000;
    //             margin          : 100px 0px;
    //             inset           : auto auto 0px 0px;
    //             transform-origin: left bottom;
    //             transform       : rotate(270deg) translateY(100%);
    //         }
    //         .hidden {
    //             transform: scale(0);
    //         }
    //         .button-container {
    //             min-width: 20px;
    //             height   : 20px;
    //             position : relative;
    //         }
    //         .message-container {
    //             box-shadow   : 0 0 18px 8px rgba(0, 0, 0, 0.1), 0 0 32px 32px rgba(0, 0, 0, 0.08);
    //             bottom       : 10px;
    //             left         : 35px;
    //             position     : absolute;
    //             max-height   : 80vh;
    //             border-radius: 4px;
    //             overflow-y   : auto;
    //             transition   : max-height .2s ease;
    //             font-family: 'Inter', sans-serif;
    //         }

    //         p{
    //             font-family: 'Inter', sans-serif;
    //         }

    //         .upperDescription{
    //             word-break: break-word;
    //         }

    //         .message-container {
    //             -ms-overflow-style: none;  /* Internet Explorer 10+ */
    //             scrollbar-width: none;  /* Firefox */
    //         }
    //         .message-container::-webkit-scrollbar { 
    //             display: none;  /* Safari and Chrome */
    //         }

    //         .message-container.hidden {
    //             max-height      : 0px;
    //         }

    //         #copyIcon{
    //             padding-top: 12px;
    //             cursor     : pointer;
    //             width      : 60px;
    //             height     : 60px;
    //             margin-top : 24px;
    //         }
            
    //         #copyIcon:hover svg path{
    //             fill : #2F80ED;
    //         }

    //         .message-container h2 {
    //             margin          : 0;
    //             padding         : 20px 20px;
    //             color           : #fff;
    //             background-color: #04b73f;
    //         }
    //         .message-container .content {
    //             margin          : 20px 10px ;
    //             border          : 1px solid #dbdbdb;
    //             padding         : 10px;
    //             display         : flex;
    //             background-color: #fff;
    //             flex-direction  : column;
    //         }
    //         .message-container form * {
    //             margin: 5px 0;
    //         }
    //         .message-container form input {
    //             padding: 10px;
    //         }
    //         .message-container form textarea {
    //             height: 100px;
    //             padding: 10px;
    //         }
    //         .message-container form textarea::placeholder {
    //             font-family: Helvetica, Arial ,sans-serif;
    //         }
    //         .message-container form button {
    //             cursor          : pointer;
    //             background-color: #04b73f;
    //             color           : #fff;
    //             border          : 0;
    //             border-radius   : 4px;
    //             padding         : 10px;
    //         }
    //         .message-container form button:hover {
    //             background-color: #16632f;
    //         }
    //         .box-shadow{
    //             box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    //         }

    //         .topBox{
    //             width                  : 420px;
    //             height                 : 140px;
    //             padding                : 24px 24px 32px 24px;
    //             box-sizing             : border-box;
    //             border-top-left-radius : 4px;
    //             border-top-right-radius: 4px;
    //         }
    
    //         .topBoxOverlay{
    //             position  : absolute;
    //             width     : 100%;
    //             height    : 100%;
    //             top       : 0;
    //             z-index   : -1;
    //             left      : 0;
    //             background: linear-gradient(141.86deg, rgba(0, 0, 0, 0) 21.99%, #000000 205.74%);
    //         }

    //         #singleDeal:last-chid{
    //             border : 0px !important;
    //         }
    
    //         .widgetLogo{
    //             height       : 40px;
    //             width        : 40px;
    //             border-radius: 50%;
    //         }
    
    //         .widgetBox{
    //             width        : 420px;
    //             border-radius: 4px;
    //         }
    
    //         .upperBox{
    //             box-shadow   : 0px 4px 6px rgba(0, 0, 0, 0.08);
    //             border-radius: 4px;
    //             padding      : 20px;
    //             margin-top   : -42px;
    //         }

    //         .ellipses{
    //             width: 230px;
    //             white-space: nowrap;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             font-family: "Inter", sans-serif;
    //         }

    //         .capitalize{
    //             text-transform: capitalize;
    //         }
    
    //         .joinBtn{
    //             border-radius: 6px;
    //             padding      : 16px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //             width        : 100%;
    //             border       : 0px;
    //             line-height  : 20px;
    //             font-size    : 14px;
    //             font-family  : "Inter", sans-serif;
    //         }

    //         .confirmBtn{
    //             border-radius: 6px;
    //             padding      : 16px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //         }

    //         #registerButton{
    //             border-radius: 6px;
    //             padding      : 18px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //             font-weight  : 500;
    //             font-size    : 14px;
    //             line-height  : 20px;
    //         }

    //         .signInButtonMain{
    //             cursor          : pointer;
    //             border-radius   : 6px;
    //             padding         : 14px 24px;
    //             margin-top      : 8px;
    //             text-align      : center;
    //             font-weight     : 500;
    //             width           : 100%;
    //             outline         : 0px;
    //             border          : 0px;
    //             font-size       : 14px;
    //             background-color: lightgray;
    //             color           : black !important;
    //             line-height     : 20px;
    //             font-family  : "Inter", sans-serif;
    //         }
    
    
    //         .crossIcon{
    //             top     : 24px;
    //             right   : 24px;
    //             position: absolute;
    //             cursor  : pointer;
    //         }
           
    //         .lowerBox{
    //             padding      : 20px;
    //             margin-top   : 16px;
    //             background   : #F5F5F5;
    //             box-shadow   : 0px 4px 6px rgba(0, 0, 0, 0.08);
    //             border-radius: 4px;
    //             position     : relative;
    //         }

    //         .d-none{
    //             display: none;
    //         }
    
    //         .error{
    //             font-size : 10px;
    //             color     : red;
    //             margin-top: 5px;
    //         }

    //         .cp{
    //             cursor: pointer;
    //         }

    //         .d-flex{
    //             display: flex;
    //         }

    //         .flex-column{
    //             flex-direction : column;
    //         }

    //         .text-center{
    //             text-align: center;
    //         }

    //         .LinkL12R {
    //             font-family   : "Inter", sans-serif;
    //             font-style    : normal;
    //             font-weight   : 400;
    //             font-size     : 12px;
    //             line-height   : 16px;
    //             letter-spacing: 0.1px;
    //         }

    //         #signInButton{
    //             border-radius: 6px;
    //             padding: 16px 24px;
    //             color: white;
    //             margin-top: 8px;
    //             text-align: center;
    //             width: 100%;
    //             border: 0px;
    //         }

    //         .BodyB14R {
    //             font-family   : "Inter", sans-serif;
    //             font-size     : 14px;
    //             font-weight   : 400;
    //             line-height   : 16px;
    //             letter-spacing: 0.1px;
    //         }
            
    //         .BodyB16R {
    //             font-family: "Inter", sans-serif;
    //             font-size  : 16px;
    //             font-weight: 400;
    //             line-height: 24px;
    //         }

    //         .HeadingH16M {
    //             font-family   : "Inter", sans-serif;
    //             font-style    : normal;
    //             font-weight   : 500;
    //             font-size     : 16px;
    //             line-height   : 24px;
    //             letter-spacing: 0.1px;
    //         }

    //         .justify-content-center{
    //             justify-content : center;
    //         }

    //         .space-between{
    //             justify-content: space-between;
    //         }

    //         .align-items-center{
    //             align-items : center;
    //         }

    //         .bottomIcon{
    //             height: 20px;
    //             width : 20px;
    //             margin-right: 16px;
    //         }

    //         .registerPage{
    //             height  : 80vh;
    //             width   : 420px;
    //             overflow: hidden;
    //         }

    //         #signInPage{
    //             height  : 520px;
    //             width   : 420px;
    //             overflow: hidden;
    //         }

    //         .topSectionSignIn{
    //             padding  : 32px 24px;
    //             width: 100%;
    //             box-sizing: border-box;
    //         }

    //         .bottomBoxSignIn{
    //             padding: 24px;
    //             height : 100%;
    //         }

    //         .bottomBoxVerification{
    //             padding: 24px;
    //             display: none;
    //             height : 100%;
    //         }

    //         .inputField{
    //             width        : 180px;
    //             height       : 45px;
    //             background   : rgba(255, 255, 255, 0.01);
    //             border       : 1px solid #D6DAE9;
    //             border-radius: 6px;
    //             outline      : 0px;
    //             font-family  : 'Inter';
    //             font-style   : normal;
    //             font-weight  : 400;
    //             font-size    : 16px;
    //             line-height  : 24px;
    //             Padding      : 8px 16px 8px 16px;
    //             box-sizing   : border-box;
    //         }

    //         .label{
    //             font-family   : 'Inter';
    //             font-style    : normal;
    //             font-weight   : 500;
    //             font-size     : 14px;
    //             line-height   : 20px;
    //             letter-spacing: 0.1px;
    //             color         : #4B4E5B;
    //             margin        : 0px 0px 5px 0px;
    //         }

    //         #qrcode{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding-top  : 24px;
    //             box-sizing   : border-box;
    //         }

    //         #earningWaysBox{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding-top  : 24px;
    //             box-sizing   : border-box;
    //         }

    //         #mainDealDisplayBox{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding      : 24px;
    //             overflow     : auto;
    //             box-sizing   : border-box;
    //         }

            

    //         #closeIconQr{
    //             position: absolute;
    //             right   : 16px;
    //             top     : 16px;
    //             display : none;
    //             cursor  : pointer;
    //         }

    //         .dealCodeBox{
    //             padding-left: 24px;
    //             padding-right: 24px;
    //             grid-gap: 8px;
    //             font-family: "Inter", sans-serif;
    //         }

    //         .singleDealDigit{
    //             font-weight   : 500;
    //             font-size     : 30px;
    //             letter-spacing: 4px;
    //             font-family: "Inter", sans-serif;
    //         }

    //         #dealDisplayBox {
    //             -ms-overflow-style: none; /* for Internet Explorer, Edge */
    //             scrollbar-width: none; /* for Firefox */
    //             overflow-y: auto; 
    //             min-height: 100px;
    //         }
            
    //         #dealDisplayBox::-webkit-scrollbar {
    //             display: none; /* for Chrome, Safari, and Opera */
    //         }


            
    //         .loader {
    //             border           : 2px solid #f3f3f3;
    //             position         : absolute;
    //             top              : 14px;
    //             left             : 116px;
    //             transform        : translate(-50%,-50%);
    //             border-radius    : 50%;
    //             border-top       : 2px solid #3498db;
    //             width            : 14px;
    //             height           : 14px;
    //             -webkit-animation: spin 2s linear infinite;  /* Safari */
    //             animation        : spin 2s linear infinite;
    //             display          : none;
    //           }
              
              
    //         @-webkit-keyframes spin {
    //             0% { -webkit-transform: rotate(0deg); }
    //             100% { -webkit-transform: rotate(360deg); }
    //         }
            
    //         @keyframes spin {
    //             0% { transform: rotate(0deg); }
    //             100% { transform: rotate(360deg); }
    //         }


    //         @media screen and (max-width : 309px){
    //             #singleDeal{
    //                 flex-direction : column;
    //             }
                
    //             .dealTitle{
    //                 text-align: center !important;
    //                 margin-top: 8px;
    //             }

    //             .dealDescription{
    //                 text-align: center !important;
    //                 margin-bottom: 8px;
    //             }

    //             #closeIconQr{
    //                 right: 0px !important;
    //                 top: 0px !important;
    //             }

    //         }

    //         @media screen and (max-width: 600px){

    //             #lastName{
    //                 width : 155px !important;
    //             }

    //             #firstName{
    //                 width : 155px !important;
    //             }

    //             .message-container{
    //                 position  : fixed;
    //                 left      : 0px;
    //                 bottom    : 0px;
    //                 z-index   : 10;
    //                 max-height: 100%;
    //                 height    : 100%;
    //                 width     : 100%;
    //                 overflow  : hidden !important;
    //             }

    //             .icon{
    //                 padding  : 1px 16px;
    //                 font-size: 12px;
    //             }

    //             .topBox{
    //                 width: 100%;
    //             }

    //             .registerPage{
    //                 height: 100vh;
    //                 width : 100%;
    //             }

    //             #signInPage{
    //                 height: 100vh;
    //                 width : 100%;
    //             }

    //             .widgetBox{
    //                 height    : 100vh;
    //                 width     : 100%;
    //                 overflow-y: scroll;
    //             }
                
    //             .bottomBox{
    //                 height : 100%;
    //             }

    //             .lowerBox{
    //                 margin-top: -42px !important;
    //             }
                
    //             #flowLoader{
    //                 width: 100%;
    //             }

    //             .bottomBoxSignIn{
    //                 height: 100%;
    //             }
                
    //         }

    //         input::-webkit-outer-spin-button,
    //             input::-webkit-inner-spin-button {
    //             -webkit-appearance: none;
    //             margin: 0;
    //             }

    //             /* Firefox */
    //             input[type=number] {
    //             -moz-appearance: textfield;
    //         }

            
    //         .skeleton {
    //             background-color: #a5a5a5;
    //             animation       : skeleton-animation 1s infinite linear;
    //         }
            
    //         @keyframes skeleton-animation {
    //             0% {
    //             opacity: 0.2;
    //             }
            
    //             50% {
    //             opacity: 0.5;
    //             }
            
    //             100% {
    //             opacity: 0.2;
    //             }
    //         }
            



    //     `.replace(/^\s+|\n/gm, '');
    //     document.head.appendChild(styleTag);
    // }

//     // ... keep other methods the same until getCampaign method ...

//     async getCampaign(){
//         let brandId = document.currentScript.getAttribute('brand').split('&')[1];
//         await axios.get(`https://api.walletly.ai/api/v4/campaign/public?brandId=${brandId}&campaignType=5`).then((resp) => {
//             this.campaign = resp.data.data[0];
//         }).catch(function (error) {
//             console.log('err', error);
//         });

//         await axios.get(`https://api.walletly.ai/api/v4/growthtool?brandId=${brandId}&growthtoolType=widget&campaignId=${this.campaign._id}`).then((resp) => {
//             this.growthTool = resp.data.data[0];
//         }).catch(function (error) {
//         });

//         await axios.get(`https://api.walletly.ai/api/v4/earning/public?brandId=${brandId}&campaignId=${this.campaign._id}`).then((resp) => {
//             let filteredEarningWays = resp.data.data.filter((earningWay) => {
//                 return ((earningWay.active) && (earningWay.activity != "checkIn"));
//             });
//             this.earningWays = filteredEarningWays;
//         }).catch(function (error) {
//         });

//         this.initialise(this.growthTool);
//     }

//     // ... rest of the methods remain the same ...
// }






import axios from "axios";

export class Widget {
    constructor({ position = 'bottom-right'}) {
        this.defaultPosition = position;
        this.baseUrl  = 'https://api.walletly.ai/';
        this.open     = false;
        // this.getCampaign   = this.getCampaign();
        this.getBrand      = this.getBrand();
        this.getChannel    = this.getChannel();
        // this.createStyles();
        this.GiftIcon         = window.location.origin + '/assets/gift.svg';
        this.StarIcon         = window.location.origin + '/assets/star.svg';
        this.brand            = {};
        this.channel          = {};
        // this.redeemingWays    = [];
        // this.campaign         = {};
        // this.growthTool       = {};
        // this.earningWays      = {};
        // this.flows            = {};
        // this.passGrowthtool   = {};
        this.brandDetails     = document.currentScript.getAttribute('brand').split('&')
    }

    // getPosition(position) {
    //     const [vertical, horizontal] = (this.channel?.widget?.position || position).split('-');
    //     return {
    //         [vertical]: '0px',
    //         [horizontal]: '0px',
    //     };
    // }

    applySizeStyles(element) {
        const size = this.channel?.widget?.size || 'medium';
        switch(size.toLowerCase()) {
            case 'small':
                element.style.fontSize = '12px';
                element.style.padding = '7px 15px';
                break;
            case 'large':
                element.style.fontSize = '24px';
                element.style.padding = '17px 25px';
                break;
            default: // medium
                element.style.fontSize = '18px';
                element.style.padding = '12px 20px';
        }
    }

    initialise() {
        setTimeout(() => {
            const container = document.createElement('div');
            container.style.position = 'unset';
            container.style.zIndex = '1000';
            
            // Apply position
            // const position = this.channel?.widget?.position || this.defaultPosition;
            // const [vertical, horizontal] = position.split('-');
            // container.style[vertical] = '0px';
            // container.style[horizontal] = '0px';
            
            document.body.appendChild(container);

            const buttonContainer = document.createElement('div');
            buttonContainer.classList.add('button-container');
            buttonContainer.setAttribute("id", "widgetButton");

            // Create button element
            console.log('logf',this.channel);
            
            const chatIcon = document.createElement('button');
            console.log('chatIcon',chatIcon);
            
            chatIcon.innerHTML = this.channel?.widget?.text;
            chatIcon.style.color = this.channel?.widget?.textColor || '#ffffff';
            chatIcon.style.backgroundColor = this.channel?.widget?.backgroundColor || '#000000';
            chatIcon.style.border = 'none';
            chatIcon.style.borderRadius = '4px';
            chatIcon.style.cursor = 'pointer';
            chatIcon.style.transition = 'transform 0.2s ease';
            chatIcon.style.position = 'absolute';
            chatIcon.style.width = 'max-content';
            chatIcon.style.cursor = 'pointer';
            chatIcon.style.zIndex = '1000';
            
            if(this.channel?.widget?.position === 'top-right'){
                chatIcon.style.top = '0px';
                chatIcon.style.right = '100px';
            }
            if(this.channel?.widget?.position === 'top-left'){
                chatIcon.style.top = '0px';
                chatIcon.style.left = '100px';
            }
            if(this.channel?.widget?.position === 'bottom-right'){
                chatIcon.style.bottom = '0px';
                chatIcon.style.right = '100px';
            }
            if(this.channel?.widget?.position === 'bottom-left'){
                chatIcon.style.bottom = '0px';
                chatIcon.style.left = '100px';
            }
            
            if(this.channel?.widget?.position === 'right-top'){
                chatIcon.style.top = '100px';
                chatIcon.style.right = this.channel?.widget?.size==='small'?'-10px':this.channel?.widget?.size==='medium'?'-5px':'0px';
                chatIcon.style.transform = 'rotate(270deg) translateY(100%)';
            }
            if(this.channel?.widget?.position === 'left-top'){
                chatIcon.style.top = '100px';
                // chatIcon.style.left = this.channel?.widget?.size==='small'?'-15px':this.channel?.widget?.size==='medium'?'-12px':'-10px';
                chatIcon.style.left = this.channel?.widget?.size==='small'?'-7px':this.channel?.widget?.size==='medium'?'-3px':'0px';
                chatIcon.style.transform = 'rotate(450deg) translateY(100%)';
            }
            if(this.channel?.widget?.position === 'right-bottom'){
                chatIcon.style.bottom = '100px';
                chatIcon.style.right = this.channel?.widget?.size==='small'?'-10px':this.channel?.widget?.size==='medium'?'-5px':'0px';
                chatIcon.style.transform = 'rotate(270deg) translateY(100%)';
            }
            if(this.channel?.widget?.position === 'left-bottom'){
                chatIcon.style.bottom = '100px';
                chatIcon.style.left = this.channel?.widget?.size==='small'?'-7px':this.channel?.widget?.size==='medium'?'-3px':'0px';
                chatIcon.style.transform = 'rotate(450deg) translateY(100%)';
            }

            
            
            // Apply size styles
            this.applySizeStyles(chatIcon);
            // this.getPosition(chatIcon);

            // // Add hover effect
            // chatIcon.addEventListener('mouseenter', () => {
            //     chatIcon.style.transform = 'scale(1.05)';
            // });
            // chatIcon.addEventListener('mouseleave', () => {
            //     chatIcon.style.transform = 'scale(1)';
            // });

            // Click handler
            chatIcon.addEventListener('click', (e) => {
                e.preventDefault();
                console.log(this.brand._id);
                if (this.brand._id) {
                    window.open('https://page.updaterewards.com/'+this.brand._id, '_blank');
                }
            });

            buttonContainer.appendChild(chatIcon);
            container.appendChild(buttonContainer);
            // this.applyThemeChange();
        }, 1000);
    }

    async getBrand(){
        let brandId = document.currentScript.getAttribute('brand').split('&')[1]
        await axios.get(`https://api.walletly.ai/api/v4/brand?_id=${brandId}`).then((resp) => {
            this.brand = resp.data.data[0];
        }).catch(function (error) {
        })
    }

    // async getCampaign(){
    //     let brandId = document.currentScript.getAttribute('brand').split('&')[1]
    //     // await axios.get(`https://api.walletly.ai/api/v4/campaign/public?brandId=${brandId}&campaignType=5`).then((resp) => {
    //     //     console.log('campaign: ', resp);
    //     //     this.campaign = resp.data.data[0];
    //     // }).catch(function (error) {
    //     //     console.log('err', error)
    //     // })

    //     await axios.get(`https://api.walletly.ai/api/v4/growthtool?brandId=${brandId}&growthtoolType=widget`).then((resp) => {
    //         this.growthTool = resp.data.data[0];
    //         console.log('growthtool ', resp.data.data)

    //     }).catch(function (error) {
    //     })

    //     // await axios.get(`https://api.walletly.ai/api/v4/earning/public?brandId=${brandId}&campaignId=${this.campaign._id}`).then((resp) => {
    //     //     let filteredEarningWays = resp.data.data.filter((earningWay)=>{
    //     //         return ((earningWay.active) && (earningWay.activity != "checkIn"))
    //     //     })
    //     //     this.earningWays = filteredEarningWays;

    //     // }).catch(function (error) {
    //     // })

    //     setTimeout(() => {
    //         this.initialise();
    //     }, 3000);
    // }
    // ... keep other methods the same until getChannel ...

    async getChannel() {
        await axios.get(`${this.baseUrl}/api/v4/growthtool?brandId=${document.currentScript.getAttribute('brand').split('&')[1]}&growthtoolType=widget`)
            .then((resp) => {
                this.channel = resp.data.data[0];
                console.log('Channel config:', this.channel?.widget);
                this.initialise()
            }).catch(error => {
                console.error('Error fetching channel:', error);
            });
    }


    // applyThemeChange(){
    //     document.getElementsByClassName('icon')[0].style.background = this.growthTool.widget.backgroundColor;
    //     if(this.growthTool.widget.theme == 'light'){
    //         document.getElementById         ('brandName').style.color           = "#000000"
    //         document.getElementsByClassName('lowerBox')[0].style.background     = 'rgb(245, 245, 245)'
    //         document.getElementsByClassName('bottomBox')[0].style.background    = '#ffffff'
    //         document.getElementsByClassName('upperDescription')[0].style.color  = 'rgb(89, 97, 115)'
    //         document.getElementsByClassName('upperDescription')[1].style.color  = 'rgb(89, 97, 115)'
    //         document.getElementsByClassName('ways')[0].style.color              = '#000000'
    //         document.getElementsByClassName('ways')[1].style.color              = '#000000'
    //         document.getElementsByClassName('upperBox')[0].style.background     = '#F5F5F5'
    //     }
    //     else{
    //         if(document.getElementById         ('brandName'))document.getElementById         ('brandName').style.color           = "#ffffff"
    //         // document.getElementsByClassName('lowerBox')[0].style.background     = 'rgb(46, 48, 57)'
    //         // document.getElementsByClassName('bottomBox')[0].style.background    = 'rgb(20, 20, 20)'
    //         // document.getElementsByClassName('upperBox')[0].style.background     = 'rgb(46, 48, 57)'
    //         // document.getElementsByClassName('upperDescription')[0].style.color  = '#f5f7fc'
    //         // document.getElementsByClassName('upperDescription')[1].style.color  = '#f5f7fc'
    //         // document.getElementsByClassName('upperTitle')[0].style.color        = 'rgb(252, 252, 253)'
    //         // document.getElementsByClassName('ways')[0].style.color              = 'rgb(252, 252, 253)'
    //     }
    //     // document.getElementsByClassName('topBox')[0].style.background     = this.growthTool.widget.primaryColor
    //     // document.getElementsByClassName('joinBtn')[0].style.background    = this.growthTool.widget.primaryColor
    //     // if(document.getElementById('registerConfirmBtn')){document.getElementById('registerConfirmBtn').style.background = this.growthTool.widget.primaryColor}
    //     // if(document.getElementById('signInConfirmBtn')){document.getElementById('signInConfirmBtn').style.background = this.growthTool.widget.primaryColor}
    //     // document.getElementsByClassName('signIn')[0].style.color = this.growthTool.widget.primaryColor
    //     // document.getElementById         ('star').style.fill      = this.growthTool.widget.primaryColor

    //     // let earnWays = document.getElementById('mainEarnWays');
    //     // if(earnWays){
    //     //     earnWays.addEventListener('click', ()=>{
    //     //         let EarningWaysBox = document.getElementById('earningWaysBox')
    //     //         EarningWaysBox.style.display = "block"
    
    //     //         let subtitle                  = document.createElement('p');
    //     //         subtitle.innerHTML        = "Earning Ways: ";
    //     //         subtitle.style.color      = this.growthTool.widget.theme == 'dark' ? '#ffffff' : '#000000'
    //     //         subtitle.style.marginLeft = '24px'
    //     //         EarningWaysBox.style.overflow = "auto"
            
    //     //         EarningWaysBox.appendChild(subtitle);
    //     //         for(let earnway of this.earningWays){
    //     //             console.log('1',earnway)
    //     //             var singleEarnWay             = document.createElement('div');
                    
    //     //             let singleEarningways         = `
    //     //             <div id="singleDeal" style="display: flex; margin: 0px 24px 0px 24px !important; align-items: center; border-bottom: 1px solid ${this.growthTool.widget.theme == 'light' ? 'lightgray' : '#333333'}; padding: 20px 0px;">
    //     //                     <img src=${
    //     //                         earnway.activity === "checkIn" ? "https://api.walletly.ai/images/loyalty/checkin.png" :
    //     //                         earnway.activity === "signup" ? "https://api.walletly.ai/images/loyalty/smile.png" :
    //     //                         earnway.activity === "birthday" ? "https://api.walletly.ai/images/loyalty/birthday.png" :
    //     //                         earnway.activity === "spending" ? "https://api.walletly.ai/images/loyalty/spending.png" :
    //     //                         earnway.icon} style="height: 32px; overflow: hidden; object-fit: contain; width: 32px; border-radius: 4px; border: 1px solid ${this.growthTool.widget.theme == 'light' ? 'lightgray' : '#333333'}" />
    //     //                     <div style="width: 200px; margin-left: 16px; margin-right:16px;">
    //     //                         <div class="capitalize" style="color:${this.growthTool.widget.theme == 'light' ? '#000000' : 'rgb(252, 252, 253)'}; font-size: 14; overflow: hidden;  text-align: left; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; font-weight: 400;">
    //     //                             ${earnway.activity}
    //     //                         </div>
    //     //                         <div style="font-size: 14px; margin-top: 4px; text-align: left; font-weight: 400; color:${this.growthTool.widget.theme == 'light' ? 'rgb(89, 97, 115)' : 'rgba(140, 148, 166, 1)'}">
    //     //                             ${earnway?.description ? earnway?.description : "b"}
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //             `
    //     //             singleEarnWay.innerHTML = singleEarningways;
    //     //             EarningWaysBox.appendChild(singleEarnWay);
    //     //         }
                
    //     //         let closeIcon                 = document.createElement('div');
    //     //         closeIcon.setAttribute('id','closeIconQr');
    //     //         closeIcon.style.display = "block";
    
    //     //         let closeIconSvg  = document.createElement('div');
    //     //         closeIconSvg.innerHTML = `
    //     //             <div>
    //     //                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     //                 <path fill=${this.growthTool.widget.theme == 'light' ? '#000000' : '#ffffff'} fill-rule="evenodd" clip-rule="evenodd" d="M0.450509 0.476124C0.632436 0.294813 0.879047 0.192972 1.13617 0.192972C1.39329 0.192972 1.63991 0.294813 1.82183 0.476124L5.98755 4.63292L10.1533 0.476124C10.2421 0.381 10.3492 0.304703 10.4682 0.251786C10.5873 0.198868 10.7157 0.170413 10.846 0.168119C10.9763 0.165826 11.1057 0.18974 11.2265 0.238435C11.3473 0.28713 11.4571 0.359609 11.5492 0.451547C11.6414 0.543486 11.714 0.653 11.7628 0.773558C11.8116 0.894115 11.8356 1.02325 11.8333 1.15325C11.831 1.28325 11.8024 1.41145 11.7494 1.53022C11.6964 1.64898 11.6199 1.75587 11.5246 1.84451L7.35888 6.0013L11.5246 10.1581C11.6199 10.2467 11.6964 10.3536 11.7494 10.4724C11.8024 10.5912 11.831 10.7194 11.8333 10.8494C11.8356 10.9794 11.8116 11.1085 11.7628 11.229C11.714 11.3496 11.6414 11.4591 11.5492 11.5511C11.4571 11.643 11.3473 11.7155 11.2265 11.7642C11.1057 11.8129 10.9763 11.8368 10.846 11.8345C10.7157 11.8322 10.5873 11.8037 10.4682 11.7508C10.3492 11.6979 10.2421 11.6216 10.1533 11.5265L5.98755 7.36969L1.82183 11.5265C1.6379 11.6975 1.39462 11.7906 1.14326 11.7862C0.891889 11.7818 0.652056 11.6801 0.474285 11.5028C0.296513 11.3254 0.194683 11.086 0.190248 10.8352C0.185813 10.5844 0.279119 10.3416 0.450509 10.1581L4.61623 6.0013L0.450509 1.84451C0.268808 1.66297 0.166748 1.41689 0.166748 1.16032C0.166748 0.903744 0.268808 0.657662 0.450509 0.476124V0.476124Z" />
    //     //                 </svg>
    //     //             </div>
    //     //         `
    //     //         closeIcon.appendChild(closeIconSvg);
    //     //         console.log('close Icon ', closeIcon);
    //     //         closeIcon.addEventListener('click', (event)=>{
    //     //             EarningWaysBox.innerHTML = "";
    //     //             EarningWaysBox.style.display = "none";
    //     //         })
    
    //     //         EarningWaysBox.appendChild(closeIcon);
    
    //     //         console.log('this ', this.earningWays)
    //     //     })
    //     // }

    // }   


    // createStyles() {
        
        
        
    //     // btn container
    //     // position : relative;
    //     const styleTag = document.createElement('style');
    //     styleTag.innerHTML = `
    //     .icon {
    //         bottom          : 120px;
    //                 left            : -54px;
    //         position        : fixed;

    //             cursor          : pointer;
    //             width           : fit-content;
    //             height          : fit-content;
    //             position        : absolute;
    //             border-radius   : 3px;
    //             padding         : 8px 16px;
    //             transition      : transform .3s ease;
    //             font-family     : "Inter", sans-serif;
    //             font-size       : 14px;
    //             color           : white;
    //             line-height     : 16px;
    //             letter-spacing  : 0.1px;
    //             margin          : 0px;
    //             letter-spacing  : 1.5px;
    //             font-weight     : 500;
    //             white-space     : nowrap;
    //             z-index         : 10000;
    //             margin          : 100px 0px;
    //             inset           : auto auto 0px 0px;
    //             transform-origin: left bottom;
    //             transform       : rotate(270deg) translateY(100%);
    //         }
    //         .hidden {
    //             transform: scale(0);
    //         }
    //         .button-container {
    //             min-width: 20px;
    //             height   : 20px;
                
    //         }
    //         .message-container {
    //             box-shadow   : 0 0 18px 8px rgba(0, 0, 0, 0.1), 0 0 32px 32px rgba(0, 0, 0, 0.08);
    //             bottom       : 10px;
    //             left         : 35px;
    //             position     : absolute;
    //             max-height   : 80vh;
    //             border-radius: 4px;
    //             overflow-y   : auto;
    //             transition   : max-height .2s ease;
    //             font-family: 'Inter', sans-serif;
    //         }

    //         p{
    //             font-family: 'Inter', sans-serif;
    //         }

    //         .upperDescription{
    //             word-break: break-word;
    //         }

    //         .message-container {
    //             -ms-overflow-style: none;  /* Internet Explorer 10+ */
    //             scrollbar-width: none;  /* Firefox */
    //         }
    //         .message-container::-webkit-scrollbar { 
    //             display: none;  /* Safari and Chrome */
    //         }

    //         .message-container.hidden {
    //             max-height      : 0px;
    //         }

    //         #copyIcon{
    //             padding-top: 12px;
    //             cursor     : pointer;
    //             width      : 60px;
    //             height     : 60px;
    //             margin-top : 24px;
    //         }
            
    //         #copyIcon:hover svg path{
    //             fill : #2F80ED;
    //         }

    //         .message-container h2 {
    //             margin          : 0;
    //             padding         : 20px 20px;
    //             color           : #fff;
    //             background-color: #04b73f;
    //         }
    //         .message-container .content {
    //             margin          : 20px 10px ;
    //             border          : 1px solid #dbdbdb;
    //             padding         : 10px;
    //             display         : flex;
    //             background-color: #fff;
    //             flex-direction  : column;
    //         }
    //         .message-container form * {
    //             margin: 5px 0;
    //         }
    //         .message-container form input {
    //             padding: 10px;
    //         }
    //         .message-container form textarea {
    //             height: 100px;
    //             padding: 10px;
    //         }
    //         .message-container form textarea::placeholder {
    //             font-family: Helvetica, Arial ,sans-serif;
    //         }
    //         .message-container form button {
    //             cursor          : pointer;
    //             background-color: #04b73f;
    //             color           : #fff;
    //             border          : 0;
    //             border-radius   : 4px;
    //             padding         : 10px;
    //         }
    //         .message-container form button:hover {
    //             background-color: #16632f;
    //         }
    //         .box-shadow{
    //             box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    //         }

    //         .topBox{
    //             width                  : 420px;
    //             height                 : 140px;
    //             padding                : 24px 24px 32px 24px;
    //             box-sizing             : border-box;
    //             border-top-left-radius : 4px;
    //             border-top-right-radius: 4px;
    //         }
    
    //         .topBoxOverlay{
    //             position  : absolute;
    //             width     : 100%;
    //             height    : 100%;
    //             top       : 0;
    //             z-index   : -1;
    //             left      : 0;
    //             background: linear-gradient(141.86deg, rgba(0, 0, 0, 0) 21.99%, #000000 205.74%);
    //         }

    //         #singleDeal:last-chid{
    //             border : 0px !important;
    //         }
    
    //         .widgetLogo{
    //             height       : 40px;
    //             width        : 40px;
    //             border-radius: 50%;
    //         }
    
    //         .widgetBox{
    //             width        : 420px;
    //             border-radius: 4px;
    //         }
    
    //         .upperBox{
    //             box-shadow   : 0px 4px 6px rgba(0, 0, 0, 0.08);
    //             border-radius: 4px;
    //             padding      : 20px;
    //             margin-top   : -42px;
    //         }

    //         .ellipses{
    //             width: 230px;
    //             white-space: nowrap;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             font-family: "Inter", sans-serif;
    //         }

    //         .capitalize{
    //             text-transform: capitalize;
    //         }
    
    //         .joinBtn{
    //             border-radius: 6px;
    //             padding      : 16px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //             width        : 100%;
    //             border       : 0px;
    //             line-height  : 20px;
    //             font-size    : 14px;
    //             font-family  : "Inter", sans-serif;
    //         }

    //         .confirmBtn{
    //             border-radius: 6px;
    //             padding      : 16px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //         }

    //         #registerButton{
    //             border-radius: 6px;
    //             padding      : 18px 24px;
    //             color        : white;
    //             margin-top   : 8px;
    //             text-align   : center;
    //             font-weight  : 500;
    //             font-size    : 14px;
    //             line-height  : 20px;
    //         }

    //         .signInButtonMain{
    //             cursor          : pointer;
    //             border-radius   : 6px;
    //             padding         : 14px 24px;
    //             margin-top      : 8px;
    //             text-align      : center;
    //             font-weight     : 500;
    //             width           : 100%;
    //             outline         : 0px;
    //             border          : 0px;
    //             font-size       : 14px;
    //             background-color: lightgray;
    //             color           : black !important;
    //             line-height     : 20px;
    //             font-family  : "Inter", sans-serif;
    //         }
    
    
    //         .crossIcon{
    //             top     : 24px;
    //             right   : 24px;
    //             position: absolute;
    //             cursor  : pointer;
    //         }
           
    //         .lowerBox{
    //             padding      : 20px;
    //             margin-top   : 16px;
    //             background   : #F5F5F5;
    //             box-shadow   : 0px 4px 6px rgba(0, 0, 0, 0.08);
    //             border-radius: 4px;
    //             position     : relative;
    //         }

    //         .d-none{
    //             display: none;
    //         }
    
    //         .error{
    //             font-size : 10px;
    //             color     : red;
    //             margin-top: 5px;
    //         }

    //         .cp{
    //             cursor: pointer;
    //         }

    //         .d-flex{
    //             display: flex;
    //         }

    //         .flex-column{
    //             flex-direction : column;
    //         }

    //         .text-center{
    //             text-align: center;
    //         }

    //         .LinkL12R {
    //             font-family   : "Inter", sans-serif;
    //             font-style    : normal;
    //             font-weight   : 400;
    //             font-size     : 12px;
    //             line-height   : 16px;
    //             letter-spacing: 0.1px;
    //         }

    //         #signInButton{
    //             border-radius: 6px;
    //             padding: 16px 24px;
    //             color: white;
    //             margin-top: 8px;
    //             text-align: center;
    //             width: 100%;
    //             border: 0px;
    //         }

    //         .BodyB14R {
    //             font-family   : "Inter", sans-serif;
    //             font-size     : 14px;
    //             font-weight   : 400;
    //             line-height   : 16px;
    //             letter-spacing: 0.1px;
    //         }
            
    //         .BodyB16R {
    //             font-family: "Inter", sans-serif;
    //             font-size  : 16px;
    //             font-weight: 400;
    //             line-height: 24px;
    //         }

    //         .HeadingH16M {
    //             font-family   : "Inter", sans-serif;
    //             font-style    : normal;
    //             font-weight   : 500;
    //             font-size     : 16px;
    //             line-height   : 24px;
    //             letter-spacing: 0.1px;
    //         }

    //         .justify-content-center{
    //             justify-content : center;
    //         }

    //         .space-between{
    //             justify-content: space-between;
    //         }

    //         .align-items-center{
    //             align-items : center;
    //         }

    //         .bottomIcon{
    //             height: 20px;
    //             width : 20px;
    //             margin-right: 16px;
    //         }

    //         .registerPage{
    //             height  : 80vh;
    //             width   : 420px;
    //             overflow: hidden;
    //         }

    //         #signInPage{
    //             height  : 520px;
    //             width   : 420px;
    //             overflow: hidden;
    //         }

    //         .topSectionSignIn{
    //             padding  : 32px 24px;
    //             width: 100%;
    //             box-sizing: border-box;
    //         }

    //         .bottomBoxSignIn{
    //             padding: 24px;
    //             height : 100%;
    //         }

    //         .bottomBoxVerification{
    //             padding: 24px;
    //             display: none;
    //             height : 100%;
    //         }

    //         .inputField{
    //             width        : 180px;
    //             height       : 45px;
    //             background   : rgba(255, 255, 255, 0.01);
    //             border       : 1px solid #D6DAE9;
    //             border-radius: 6px;
    //             outline      : 0px;
    //             font-family  : 'Inter';
    //             font-style   : normal;
    //             font-weight  : 400;
    //             font-size    : 16px;
    //             line-height  : 24px;
    //             Padding      : 8px 16px 8px 16px;
    //             box-sizing   : border-box;
    //         }

    //         .label{
    //             font-family   : 'Inter';
    //             font-style    : normal;
    //             font-weight   : 500;
    //             font-size     : 14px;
    //             line-height   : 20px;
    //             letter-spacing: 0.1px;
    //             color         : #4B4E5B;
    //             margin        : 0px 0px 5px 0px;
    //         }

    //         #qrcode{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding-top  : 24px;
    //             box-sizing   : border-box;
    //         }

    //         #earningWaysBox{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding-top  : 24px;
    //             box-sizing   : border-box;
    //         }

    //         #mainDealDisplayBox{
    //             display      : none;
    //             position     : absolute;
    //             top          : 0px;
    //             bottom       : 0px;
    //             height       : inherit;
    //             width        : 100%;
    //             left         : 0px;
    //             background   : inherit;
    //             border-radius: 6px;
    //             padding      : 24px;
    //             overflow     : auto;
    //             box-sizing   : border-box;
    //         }

            

    //         #closeIconQr{
    //             position: absolute;
    //             right   : 16px;
    //             top     : 16px;
    //             display : none;
    //             cursor  : pointer;
    //         }

    //         .dealCodeBox{
    //             padding-left: 24px;
    //             padding-right: 24px;
    //             grid-gap: 8px;
    //             font-family: "Inter", sans-serif;
    //         }

    //         .singleDealDigit{
    //             font-weight   : 500;
    //             font-size     : 30px;
    //             letter-spacing: 4px;
    //             font-family: "Inter", sans-serif;
    //         }

    //         #dealDisplayBox {
    //             -ms-overflow-style: none; /* for Internet Explorer, Edge */
    //             scrollbar-width: none; /* for Firefox */
    //             overflow-y: auto; 
    //             min-height: 100px;
    //         }
            
    //         #dealDisplayBox::-webkit-scrollbar {
    //             display: none; /* for Chrome, Safari, and Opera */
    //         }


            
    //         .loader {
    //             border           : 2px solid #f3f3f3;
    //             position         : absolute;
    //             top              : 14px;
    //             left             : 116px;
    //             transform        : translate(-50%,-50%);
    //             border-radius    : 50%;
    //             border-top       : 2px solid #3498db;
    //             width            : 14px;
    //             height           : 14px;
    //             -webkit-animation: spin 2s linear infinite;  /* Safari */
    //             animation        : spin 2s linear infinite;
    //             display          : none;
    //           }
              
              
    //         @-webkit-keyframes spin {
    //             0% { -webkit-transform: rotate(0deg); }
    //             100% { -webkit-transform: rotate(360deg); }
    //         }
            
    //         @keyframes spin {
    //             0% { transform: rotate(0deg); }
    //             100% { transform: rotate(360deg); }
    //         }


    //         @media screen and (max-width : 309px){
    //             #singleDeal{
    //                 flex-direction : column;
    //             }
                
    //             .dealTitle{
    //                 text-align: center !important;
    //                 margin-top: 8px;
    //             }

    //             .dealDescription{
    //                 text-align: center !important;
    //                 margin-bottom: 8px;
    //             }

    //             #closeIconQr{
    //                 right: 0px !important;
    //                 top: 0px !important;
    //             }

    //         }

    //         @media screen and (max-width: 600px){

    //             #lastName{
    //                 width : 155px !important;
    //             }

    //             #firstName{
    //                 width : 155px !important;
    //             }

    //             .message-container{
    //                 position  : fixed;
    //                 left      : 0px;
    //                 bottom    : 0px;
    //                 z-index   : 10;
    //                 max-height: 100%;
    //                 height    : 100%;
    //                 width     : 100%;
    //                 overflow  : hidden !important;
    //             }

    //             .icon{
    //                 padding  : 1px 16px;
    //                 font-size: 12px;
    //             }

    //             .topBox{
    //                 width: 100%;
    //             }

    //             .registerPage{
    //                 height: 100vh;
    //                 width : 100%;
    //             }

    //             #signInPage{
    //                 height: 100vh;
    //                 width : 100%;
    //             }

    //             .widgetBox{
    //                 height    : 100vh;
    //                 width     : 100%;
    //                 overflow-y: scroll;
    //             }
                
    //             .bottomBox{
    //                 height : 100%;
    //             }

    //             .lowerBox{
    //                 margin-top: -42px !important;
    //             }
                
    //             #flowLoader{
    //                 width: 100%;
    //             }

    //             .bottomBoxSignIn{
    //                 height: 100%;
    //             }
                
    //         }

    //         input::-webkit-outer-spin-button,
    //             input::-webkit-inner-spin-button {
    //             -webkit-appearance: none;
    //             margin: 0;
    //             }

    //             /* Firefox */
    //             input[type=number] {
    //             -moz-appearance: textfield;
    //         }

            
    //         .skeleton {
    //             background-color: #a5a5a5;
    //             animation       : skeleton-animation 1s infinite linear;
    //         }
            
    //         @keyframes skeleton-animation {
    //             0% {
    //             opacity: 0.2;
    //             }
            
    //             50% {
    //             opacity: 0.5;
    //             }
            
    //             100% {
    //             opacity: 0.2;
    //             }
    //         }
            



    //     `.replace(/^\s+|\n/gm, '');
    //     document.head.appendChild(styleTag);
    // }
    // ... rest of the methods remain the same ...
}